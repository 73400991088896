 
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$md-bitbybit: (
    50: #535a66,
    100: #484d58,
    200: #3c414a,
    300: #31353c,
    400: #25282e,
    500: #1A1C20,
    600: #0f1012,
    700: #030304,
    800: #000000,
    900: #000000,
    A100: #5f6674,
    A200: #6a7282,
    A400: #767f90,
    A700: #000000,
    contrast: (
        50: #535a66,
        100: #484d58,
        200: #3c414a,
        300: #31353c,
        400: #25282e,
        500: #1A1C20,
        600: #0f1012,
        700: #030304,
        800: #000000,
        900: #000000,
        A100: #5f6674,
        A200: #6a7282,
        A400: #767f90,
        A700: #000000
    )
);


$md-bitbybit-accent: (
    50: #de6825,
    100: #e1773b,
    200: #e48752,
    300: #e89668,
    400: #eba57e,
    500: #efb594,
    600: #f5d3c0,
    700: #f9e3d6,
    800: #fcf2ec,
    900: #ffffff,
    A100: #f5d3c0,
    A200: #F2C4AA,
    A400: #efb594,
    A700: #ffffff,
    contrast: (
        50: #de6825,
        100: #e1773b,
        200: #e48752,
        300: #e89668,
        400: #eba57e,
        500: #efb594,
        600: #f5d3c0,
        700: #f9e3d6,
        800: #fcf2ec,
        900: #ffffff,
        A100: #f5d3c0,
        A200: #F2C4AA,
        A400: #efb594,
        A700: #ffffff
    )
);
$bitbybit-ui-primary: mat-palette($md-bitbybit-accent);
$bitbybit-ui-accent: mat-palette($md-bitbybit-accent);

// The warn palette is optional (defaults to red).
$bitbybit-ui-warn: mat-palette($mat-orange);

// Create the theme object (a Sass map containing all of the palettes).
$bitbybit-ui-theme: mat-dark-theme($bitbybit-ui-primary, $bitbybit-ui-accent, $bitbybit-ui-warn);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($bitbybit-ui-theme);

$custom-typography: mat-typography-config(
  $font-family: '"IBM Plex Sans", sans-serif',
  $headline: mat-typography-level(32px, 32px, 400),
  $body-1: mat-typography-level(16px, 24px, 200)
);
// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat-base-typography($custom-typography);

// Override typography for a specific Angular Material components.
@include mat-checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; scroll-behavior: smooth;}
body { 
    margin: 0; 
    background-color: #1a1c1f;
    color: white;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mat-tooltip {
    font-weight: 400;
}
